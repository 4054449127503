import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import XxxRsiTemplate from '@/templates/crypto/xxx-rsi';

export default function BitcoinRsiChart({ location }: GatsbyPageProps) {
  return (
    <XxxRsiTemplate
      coinAbbreviation="BTC"
      coinFullName="Bitcoin"
      token={Token.BTCUSDT}
      location={location}
      articleId={ArticleList.BITCOIN_RSI}
    ></XxxRsiTemplate>
  );
}
